/**
 * Polyfill stable language features. These imports will be optimized by `@babel/preset-env`.
 *
 * See: https://github.com/zloirock/core-js#babel
 */
import "core-js/stable";
import "regenerator-runtime/runtime";

/* Without this, we get a 'global not defined error' for some reason. Seems to be an Angular issue
 which is what nx uses under the hood. Please see:
https://stackoverflow.com/questions/54201934/referenceerror-global-is-not-defined-with-stream-and-angular-7-1
https://github.com/angular/angular-cli/issues/8160

*/

// eslint-disable-next-line
(window as any).global = window;
